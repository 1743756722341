<template>
  <!-- NAME[epic=财务] 科目余额查询 -->
  <div class="orderTest-container">
    <div style="margin-bottom: 15px">
      <el-select
        v-model="year"
        clearable
        style="width: 160px; margin-right: 15px"
        placeholder="选择年"
        @change="yearChange"
      >
        <el-option
          v-for="(i, idx) in yearSelect"
          :key="idx"
          :value="i.id"
          :label="i.name"
        ></el-option>
      </el-select>
      <el-select
        v-model="month"
        clearable
        style="width: 160px; margin-right: 15px"
        placeholder="选择月"
      >
        <el-option
          v-for="(i, idx) in monthSelect"
          :key="idx"
          :value="i.id"
          :label="i.name"
        ></el-option>
      </el-select>
      <!-- <el-date-picker
        v-model="year"
        type="year"
        value-format="yyyy年"
        placeholder="选择年"
        style="margin-right: 15px"
      ></el-date-picker>
      <el-date-picker
        v-model="month"
        type="month"
        format="M月"
        value-format="MM"
        placeholder="选择月"
        style="margin-right: 15px"
      ></el-date-picker> -->
      <el-button type="primary" @click="fetchData">查询</el-button>
      <!-- <el-button @click="handlePrint">打印</el-button>
      <el-button @click="handleExport">导出</el-button> -->
    </div>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :indent="34"
      default-expand-all
    >
      <el-table-column
        prop="bill_code"
        label="科目代码"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="科目名称"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column prop="" label="期初余额" width="" align="center">
        <el-table-column
          prop="begin_money_add"
          label="借方"
          width=""
          align="center"
        ></el-table-column>
        <el-table-column
          prop="begin_money_less"
          label="贷方"
          width=""
          align="center"
        ></el-table-column>
      </el-table-column>
      <el-table-column prop="" label="本期发生额" width="" align="center">
        <el-table-column
          prop="diff_money_add"
          label="借方"
          width=""
          align="center"
        ></el-table-column>
        <el-table-column
          prop="diff_money_less"
          label="贷方"
          width=""
          align="center"
        ></el-table-column>
      </el-table-column>
      <el-table-column prop="" label="期末余额" width="" align="center">
        <el-table-column
          prop="end_money_add"
          label="借方"
          width=""
          align="center"
        ></el-table-column>
        <el-table-column
          prop="end_money_less"
          label="贷方"
          width=""
          align="center"
        ></el-table-column>
      </el-table-column>
    </el-table>
    <!--    打印预览组件-->
    <!-- <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type=""
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog> -->
  </div>
</template>
<script>
  // import { exportExcel } from '@/utils/exportExcel'
  // import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { balanceQuery, monthList, yearList } from '@/api/financial'
  export default {
    name: 'KeMuYuE',
    components: {},
    data() {
      return {
        data_id: 0,
        form: {},
        year: '',
        month: '',
        loading: false,
        list: [],
        yearSelect: [],
        monthSelect: [],
      }
    },
    computed: {},
    watch: {
      month(old) {
        console.log(old)
      },
    },
    created() {
      this.selectInit()
      this.initDate()
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await balanceQuery({
          year: this.year,
          month: this.month,
        })
        this.list = data.list
        this.loading = false
      },
      yearChange(e) {
        console.log('e', e)
        this.month = ''
        monthList({
          year: e,
        }).then((res) => {
          this.monthSelect = res.data
        })
      },
      selectInit() {
        yearList().then((res) => {
          this.yearSelect = res.data
        })
        monthList({
          year: this.year,
        }).then((res) => {
          this.monthSelect = res.data
        })
      },
      initDate() {
        let date = new Date()
        this.year = date.getFullYear()
        this.month =
          date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1 + ''
      },
      handlePrint() {},
      handleExport() {
        // const list = this.list
        // const header = this.checkList
        // const columns = this.columns
        // exportExcel(header, list, columns, true, '科目余额')
      },
    },
  }
</script>
<style lang="scss" scoped></style>
